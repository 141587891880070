import React from 'react';
import styled from 'styled-components';
import { PropertiesGroupFragment } from '../../../gatsby-graphql';
import { BaseContainer } from '../BaseComponents';
import PropertiesListing from '../PropertiesListing/PropertiesListing';

const PropertiesGroup: React.FC<PropertiesGroupFragment> = ({
  headerCaption,
  headerTitle,
  properties,
}) => {
  return (
    <BaseContainer>
      <Header>
        <h1>{headerCaption}</h1>
        <h2>{headerTitle}</h2>
      </Header>
      <PropertiesListing properties={properties} imageResolver="desktopGrid" />
    </BaseContainer>
  );
};

const Header = styled.hgroup`
  color: ${(props) => props.theme.colors.dark.primary};
  h1 {
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    margin: 60px 0 4px;
    ${(props) => props.theme.mq.desktop} {
      margin-top: 80px;
    }
  }
  h2 {
    font-size: 22px;
    line-height: 26px;
    font-weight: bold;
    margin: 0 0 35px;
  }
`;

export default React.memo(PropertiesGroup);

import { graphql, useStaticQuery } from 'gatsby';
import { AllPropertyLocationsHookQuery } from '../../../gatsby-graphql';

export const usePropertyLocations = (): AllPropertyLocationsHookQuery['allContentfulPropertyLocation'] => {
  const data = useStaticQuery<AllPropertyLocationsHookQuery>(graphql`
    query AllPropertyLocationsHook {
      allContentfulPropertyLocation {
        totalCount
        nodes {
          id
          slug
          name
        }
      }
    }
  `);

  return data.allContentfulPropertyLocation;
};

import React from 'react';
import styled from 'styled-components';
import { GatsbyLinkProps, Link } from 'gatsby';
import { buttonStyle, IStyledButtonProps } from '../../styles/globals/Buttons';
import { IButtonProps } from '../Button/Button';

export const ButtonLink: React.FC<
  IButtonProps & Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref'>
> = ({ label, buttonStyle, ...props }) => (
  <StyledButtonLink className={`${buttonStyle}`} {...props}>
    {label}
  </StyledButtonLink>
);

const StyledButtonLink = styled(Link)<IStyledButtonProps>`
  ${buttonStyle}
`;

export default React.memo(ButtonLink);

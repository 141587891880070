import React from 'react';
import styled from 'styled-components';
import { BaseContainer } from '../BaseComponents';
import {
  ContactFormFragment,
  PropertyAgentFragment,
} from '../../../gatsby-graphql';
import RequestInfoForm from '../Forms/RequestInfoForm';

const ContactForm: React.FC<ContactFormFragment> = ({
  title,
  copy,
  nameInputLabel,
  mailInputLabel,
  phoneInputLabel,
  submitLabel,
  contactAgent,
}) => {
  return (
    <BaseContainer>
      <InnerContainer>
        <div>
          <h2>{title}</h2>
          <p>{copy}</p>
        </div>
        <RequestInfoForm
          {...{ nameInputLabel, mailInputLabel, phoneInputLabel, submitLabel }}
          formId="in-page-contact"
          agent={contactAgent as PropertyAgentFragment}
        />
      </InnerContainer>
    </BaseContainer>
  );
};

const InnerContainer = styled.div`
  margin-top: 48px;
  padding: 24px 12px;
  display: grid;
  grid-template-columns: 1fr;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.grayBackground};
  align-items: center;

  ${(props) => props.theme.mq.tablet} {
    padding: 34px 30px;
  }

  ${(props) => props.theme.mq.desktop} {
    grid-template-columns: 7fr 5fr;
    padding: 54px 60px;
  }

  div {
    color: ${(props) => props.theme.colors.dark.primary};
    h2 {
      font-size: 26px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 32px;
      margin: 0 0 8px;
      ${(props) => props.theme.mq.tablet} {
        font-size: 36px;
        line-height: 42px;
      }
    }
    p {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 19px;
      margin: 0;
    }
  }

  form {
    display: flex;
    margin-top: 14px;
    flex-direction: column;

    ${(props) => props.theme.mq.tablet} {
      padding: 10px 20px;
      //background-color: ${(props) => props.theme.colors.white};
      border-radius: 8px;
    }

    ${(props) => props.theme.mq.desktop} {
      margin-top: 0;
    }
  }
`;

export default ContactForm;

import React from 'react';
import { graphql, PageRendererProps } from 'gatsby';
import { GenericPageQuery } from '../../gatsby-graphql';
import Layout from '../components/Layout/Layout';
import PageComponents from '../components/PageComponents/PageComponets';

interface IGenericTemplateProps extends PageRendererProps {
  data: GenericPageQuery;
  pageContext: {
    locale: string;
  };
}

const GenericTemplate: React.FC<IGenericTemplateProps> = ({
  data: { page, navigation, footer },
}) => (
  <Layout
    {...{ navigation, footer }}
    title={page?.seo?.pageTitle as string}
    description={page?.seo?.pageDescription as string}
    image={page?.seo?.pageImage?.file?.url as string}
  >
    <PageComponents components={page?.components} />
  </Layout>
);

export const pageQuery = graphql`
  query GenericPage($id: String!) {
    ...SiteNavigation
    page: contentfulPage(id: { eq: $id }) {
      ...Seo
      ...PageComponents
    }
  }
`;

export default GenericTemplate;

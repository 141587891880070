import React, { useContext } from 'react';
import Select, { ActionMeta, ValueType } from 'react-select';
import { ThemeContext } from 'styled-components';
import { SearchHeroFragment } from '../../../gatsby-graphql';

type SearchPropertySelectOption = {
  label: string | undefined | null;
  value: string | undefined | null;
};

type OnChange = (
  value: ValueType<SearchPropertySelectOption, false>,
  actionMeta: ActionMeta<SearchPropertySelectOption>
) => void;

interface ISearchPropertySelect {
  locationInputPlaceholder: SearchHeroFragment['locationInputPlaceholder'];
  propertyTypeAssistiveLabel: string;
  options: SearchPropertySelectOption[] | undefined;
  onChange: OnChange;
}

const SearchPropertySelect: React.FC<ISearchPropertySelect> = ({
  locationInputPlaceholder,
  propertyTypeAssistiveLabel,
  options,
  onChange,
}) => {
  const themeContext = useContext(ThemeContext);
  const customStyles = {
    indicatorSeparator: (styles) => ({
      ...styles,
      visibility: 'hidden',
      display: 'none',
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      visibility: 'hidden',
      display: 'none',
    }),
    control: (styles, state) => ({
      ...styles,
      border: state.isSelected ? 'none' : 'none',
      borderColor: state.isSelected ? 'red' : 'blue',
      boxShadow: state.isFocused ? `none` : 'none',
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: '16px',
      lineHeight: '19px',
      color: themeContext.colors.dark.primary,
    }),
    input: (styles) => ({
      ...styles,
      fontSize: '16px',
      lineHeight: '19px',
      color: themeContext.colors.dark.primary,
    }),
  };
  return (
    <Select
      ariaLabel={propertyTypeAssistiveLabel}
      placeholder={locationInputPlaceholder}
      isSearchable={true}
      styles={customStyles}
      onChange={onChange}
      options={options}
      maxMenuHeight={250}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: themeContext.colors.primary,
          primary25: '#EBEFEE',
          neutral80: themeContext.colors.primary,
          neutral60: themeContext.colors.primary,
          neutral40: themeContext.colors.primary,
          neutral20: themeContext.colors.primary,
        },
      })}
    />
  );
};

export default React.memo(SearchPropertySelect);

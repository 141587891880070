import React, { useState } from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { BaseContainer } from '../BaseComponents';
import { SearchHeroFragment } from '../../../gatsby-graphql';
import SearchForm from '../SearchForm/SearchForm';
import { usePropertyLocations } from '../../graphql/hooks';

const SearchBanner: React.FC<SearchHeroFragment> = ({
  propertyTypes,
  operationTypes,
  locationInputLabel,
  locationInputPlaceholder,
  searchSubmitLabel,
  propertyTypeAssistiveLabel,
}) => {
  const { nodes: propertyLocations } = usePropertyLocations();
  const [activeOperationType, setActiveOperationType] = useState<number>(0);
  const [activePropertyType, setActivePropertyType] = useState<number>(0);
  const [
    activePropertyLocation,
    setActivePropertyLocation,
  ] = useState<number>();

  const getListingUrl = (): string => {
    let listingUrl = `/${operationTypes[activeOperationType].slug}/${propertyTypes[activePropertyType].slug}`;
    if (activePropertyLocation >= 0) {
      listingUrl = listingUrl.concat(
        `/${propertyLocations[activePropertyLocation].slug}`
      );
    }
    return listingUrl;
  };

  return (
    <StyledBaseContainer>
      <h1>{operationTypes[activeOperationType].heroTitle}</h1>
      <ImagesContainer>
        <div>
          <GatsbyImage
            alt={propertyTypes[activePropertyType].secondaryHeroImage?.description as string}
            image={propertyTypes[activePropertyType].secondaryHeroImage?.image}
            draggable={false}
          />
        </div>
        <div>
          <GatsbyImage
            alt={propertyTypes[activePropertyType].mainHeroImage?.description as string}
            image={propertyTypes[activePropertyType].mainHeroImage?.image}
            draggable={false}
          />
        </div>
      </ImagesContainer>
      <SearchContainer>
        <OperationTypeButtonsContainer>
          {operationTypes?.map((operationType, index) => (
            <button
              key={operationType?.id}
              className={index === activeOperationType ? 'active' : ''}
              onClick={() => setActiveOperationType(index)}
            >
              {operationType?.name}
            </button>
          ))}
        </OperationTypeButtonsContainer>
        <SearchForm
          {...{
            operationTypes,
            propertyTypes,
            locationInputLabel,
            locationInputPlaceholder,
            searchSubmitLabel,
            propertyTypeAssistiveLabel,
            propertyLocations,
          }}
          listingUrl={getListingUrl()}
          onPropertyTypeChange={setActivePropertyType}
          onPropertyLocationChange={setActivePropertyLocation}
          onOperationTypeChange={setActiveOperationType}
        />
      </SearchContainer>
    </StyledBaseContainer>
  );
};

const StyledBaseContainer = styled(BaseContainer)`
  margin-top: 32px;
  ${(props) => props.theme.mq.tablet} {
    margin-top: 56px;
  }
  ${(props) => props.theme.mq.desktop} {
    margin-top: 78px;
  }
  h1 {
    color: ${(props) => props.theme.colors.dark.primary};
    font-size: 31px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
    margin: 0 0 27px;
    ${(props) => props.theme.mq.tablet} {
      font-size: 41px;
      line-height: 50px;
    }
    ${(props) => props.theme.mq.desktop} {
      font-size: 51px;
      line-height: 60px;
    }
  }
`;

const ImagesContainer = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 1fr;
  column-gap: 20px;
  border-radius: 8px;
  overflow: hidden;
  ${(props) => props.theme.mq.tablet} {
    grid-template-columns: 2.91fr 9fr;
  }
  & > div {
    position: relative;
    &:first-of-type {
      display: none;
      ${(props) => props.theme.mq.tablet} {
        display: block;
      }
      &:before {
        background: ${(props) => props.theme.colors.secondary};
      }
    }
    &:last-of-type {
      &:before {
        background: ${(props) => props.theme.colors.secondary};
      }
    }
    &:before {
      content: '';
      border-radius: 8px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      z-index: 1;
    }
  }
`;

const SearchContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: -60px 20px 0;
  ${(props) => props.theme.mq.tablet} {
    margin: -84px 32px 0;
  }
  ${(props) => props.theme.mq.desktop} {
    margin: -94px 40px 0;
  }
`;

const OperationTypeButtonsContainer = styled.div`
  display: none;
  z-index: 1;
  button {
    cursor: pointer;
    padding: 14px 30px;
    background-color: ${(props) => props.theme.colors.lightSurface};
    border: none;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    color: ${(props) => props.theme.colors.secondary};
    transition: ${(props) => props.theme.generics.transitionTime};
    &:not(.active) {
      &:hover {
        color: ${(props) => props.theme.colors.dark.primary};
      }
    }
    &.active {
      color: ${(props) => props.theme.colors.white};
      background-color: ${(props) => props.theme.colors.primary};
    }
    &:first-of-type {
      border-top-left-radius: 8px;
    }
    &:last-of-type {
      border-top-right-radius: 8px;
    }
  }
  ${(props) => props.theme.mq.tablet} {
    display: block;
  }
`;

export default React.memo(SearchBanner);

import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import Select, { ActionMeta, ValueType } from 'react-select';

type PropertySearchOption = {
  label: string | undefined | null;
  value: string | undefined | null;
};

type OnChange = (
  value: ValueType<PropertySearchOption, false>,
  actionMeta: ActionMeta<PropertySearchOption>
) => void;

interface ISearchSelect {
  propertyTypeAssistiveLabel?: string | null;
  options: PropertySearchOption[] | undefined;
  onChange: OnChange;
  className?: string;
}

const SearchSelect: React.FC<ISearchSelect> = ({
  propertyTypeAssistiveLabel,
  options,
  onChange,
  className,
}) => {
  const themeContext = useContext(ThemeContext);
  const customStyles = {
    indicatorSeparator: (styles) => ({
      ...styles,
      visibility: 'hidden',
      display: 'none',
    }),
    control: (styles, state) => ({
      ...styles,
      border: state.isSelected ? 'none' : 'none',
      borderColor: state.isSelected ? 'red' : 'blue',
      boxShadow: state.isFocused ? `none` : 'none',
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: '16px',
      lineHeight: '19px',
      color: themeContext.colors.dark.primary,
      fontWeight: 'bold',
    }),
  };
  return (
    <Select
      className={className}
      ariaLabel={propertyTypeAssistiveLabel}
      isSearchable={false}
      styles={customStyles}
      options={options}
      defaultValue={options[0]}
      onChange={onChange}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: themeContext.colors.primary,
          primary25: '#EBEFEE',
          neutral80: themeContext.colors.primary,
          neutral60: themeContext.colors.primary,
          neutral40: themeContext.colors.primary,
          neutral20: themeContext.colors.primary,
        },
      })}
    />
  );
};

export default React.memo(SearchSelect);

import React from 'react';
import styled from 'styled-components';
import {
  AllPropertyLocationsHookQuery,
  SearchHeroFragment,
} from '../../../gatsby-graphql';
import SearchPropertySelect from '../SearchPropertySelect/SearchPropertySelect';
import SearchSelect from '../SearchSelect/SearchSelect';
import ButtonLink from '../ButtonLink/ButtonLink';

interface ISearchFormProps {
  operationTypes: SearchHeroFragment['operationTypes'];
  onOperationTypeChange(index: number): void;
  propertyTypes: SearchHeroFragment['propertyTypes'];
  locationInputLabel: SearchHeroFragment['locationInputLabel'];
  locationInputPlaceholder: SearchHeroFragment['locationInputPlaceholder'];
  searchSubmitLabel: SearchHeroFragment['searchSubmitLabel'];
  propertyTypeAssistiveLabel: SearchHeroFragment['propertyTypeAssistiveLabel'];
  onPropertyTypeChange(index: number): void;
  propertyLocations: AllPropertyLocationsHookQuery['allContentfulPropertyLocation']['nodes'];
  onPropertyLocationChange(index: number): void;
  listingUrl: string;
}

const SearchForm: React.FC<ISearchFormProps> = ({
  operationTypes,
  onOperationTypeChange,
  propertyTypes,
  locationInputLabel,
  locationInputPlaceholder,
  searchSubmitLabel,
  propertyTypeAssistiveLabel,
  onPropertyTypeChange,
  propertyLocations,
  onPropertyLocationChange,
  listingUrl,
}) => {
  const getParsedOptions = (arr: SearchHeroFragment['propertyTypes']) =>
    arr?.map((option) => ({
      value: option?.slug,
      label: option?.name,
    }));

  const parsedOperationTypes = getParsedOptions(operationTypes);
  const parsedPropertyTypes = getParsedOptions(propertyTypes);
  const parsedPropertyLocations = getParsedOptions(propertyLocations);

  const handleOperationTypeChange = (value) => {
    onOperationTypeChange(parsedOperationTypes.indexOf(value));
  };

  const handlePropertyTypeChange = (value) => {
    onPropertyTypeChange(parsedPropertyTypes.indexOf(value));
  };

  const handlePropertyLocationChange = (value) => {
    onPropertyLocationChange(parsedPropertyLocations.indexOf(value));
  };

  return (
    <Container>
      <SearchSelect
        options={parsedPropertyTypes}
        onChange={handlePropertyTypeChange}
        propertyTypeAssistiveLabel={propertyTypeAssistiveLabel}
      />
      <SearchSelect
        options={parsedOperationTypes}
        onChange={handleOperationTypeChange}
        propertyTypeAssistiveLabel={propertyTypeAssistiveLabel}
      />
      <LocationSelectContainer>
        <span id="locationInputLabel">{locationInputLabel}</span>
        <SearchPropertySelect
          locationInputPlaceholder={locationInputPlaceholder}
          propertyTypeAssistiveLabel={locationInputLabel as string}
          options={parsedPropertyLocations}
          onChange={handlePropertyLocationChange}
        />
      </LocationSelectContainer>
      <ButtonLink
        label={searchSubmitLabel as string}
        color="primary"
        buttonStyle="solid"
        to={`${listingUrl}`}
      />
    </Container>
  );
};

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.lightSurface};
  box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px 8px 0;
  width: 100%;
  max-width: 853px;
  display: grid;
  grid-template-areas:
    'a a a b b b'
    'c c c c c c'
    'd d d d d d';
  & > div:nth-of-type(1) {
    grid-area: a;
  }
  & > div:nth-of-type(2) {
    grid-area: b;
  }
  & > div:nth-of-type(3) {
    grid-area: c;
  }
  & > a {
    grid-area: d;
    margin: 8px -8px 0;
  }
  ${(props) => props.theme.mq.tablet} {
    padding: 20px 40px;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 1rem;
    row-gap: 1rem;
    grid-template-areas:
      'a a c c c c'
      'd d d d d d';
    & > div:nth-of-type(1) {
      grid-area: a;
    }
    & > div:nth-of-type(2) {
      grid-area: b;
      display: none;
    }
    & > div:nth-of-type(3) {
      grid-area: c;
    }
    & > a {
      grid-area: d;
      margin: 0;
    }
  }
  ${(props) => props.theme.mq.desktop} {
    grid-template-areas: none;
    grid-template-columns: minmax(min-content, 174px) 1fr minmax(
        min-content,
        109px
      );
    & > div:first-of-type,
    & > div:last-of-type,
    & > a {
      grid-area: initial;
    }
  }
`;

const LocationSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 8px;
  margin-top: 8px;
  ${(props) => props.theme.mq.tablet} {
    align-items: center;
    flex-direction: row;
    margin: 0;
    padding: 0;
    border: 0;
  }
  span {
    padding-left: 8px;
    font-size: 16px;
    line-height: 21px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.dark.primary};
    ${(props) => props.theme.mq.tablet} {
      padding: 0;
    }
  }
  span + div:first-of-type {
    flex: 1;
  }
`;

export default SearchForm;

import React from 'react';
import SearchBanner from '../SearchBanner/SearchBanner';
import PropertiesGroup from '../PropertiesGroup/PropertiesGroup';
import ContactForm from '../ContactForm/ContactForm';

interface IComponents {
  [key: string]: React.FC<any>;
}

const Components: IComponents = {
  SearchBanner: SearchBanner,
  PropertiesGroup: PropertiesGroup,
  ContactForm: ContactForm,
};

export default Components;
